<template>
  <v-row>
    <v-col cols="12" lg="7">
      <v-row>
        <v-col cols="12">
          <v-switch v-model="obProduct.active" :label="$t('active')"></v-switch>
        </v-col>

        <v-col cols="12" lg="8">
          <form-field-text v-model="obProduct.name" required />
        </v-col>

        <!-- <v-col cols="12">
        <brands-select v-model="obProduct.brand_id" />
      </v-col> -->

        <v-col cols="12" lg="4">
          <code-field-text
            v-model="obProduct.code"
            :show-code-button="!obProduct.code"
            item-key="product"
            label="code"
          />
          <!--          <form-field-text v-model="obProduct.code" label="code" vid="code" />-->
        </v-col>

        <v-col cols="12" lg="4">
          <tax-types-select
            :clearable="!taxReadonly"
            :readonly="!!taxTypeId && taxReadonly"
            :value="taxTypeId"
            required
            @change="onSelectTaxType"
          />
        </v-col>

        <v-col cols="12" lg="4">
          <form-field-text
            v-model="obProductGw.barcode"
            label="barcode"
            vid="barcode"
          />
        </v-col>

        <v-col v-if="obProduct && obProduct.id" cols="12" lg="4">
          <company-labels-dialog :model="obProduct" field="DscItem" />
        </v-col>

        <template v-if="hasAccounting">
          <v-col cols="12" lg="6">
            <account-code-select
              v-model="obProductGw.account_sales"
              :disabled="!obProductGw.is_sell"
              :required="accountSalesRequired"
              label="account.sales"
              payment-method-code="NINGUNO"
            />
          </v-col>

          <v-col cols="12" lg="6">
            <account-code-select
              v-model="obProductGw.account_purchase"
              :required="accountPurchaseRequired"
              label="account.purchase"
              payment-method-code="NINGUNO"
            />
          </v-col>
        </template>

        <v-col cols="6" xl="4">
          <v-switch
            v-model="obProductGw.with_tax_expenses"
            :label="$t('with.tax.expenses')"
          ></v-switch>
        </v-col>

        <v-col cols="6" xl="4">
          <v-switch
            v-model="obProductGw.is_service"
            :label="$t('product.for.service')"
          ></v-switch>
        </v-col>

        <v-col cols="6" xl="4">
          <v-switch
            v-model="obProductGw.is_sell"
            :label="$t('product.for.sales')"
          ></v-switch>
        </v-col>

        <v-col cols="12" lg="6">
          <company-preview :company="obProduct.get('company', company)" />
        </v-col>

        <branch-select
          v-model="branchListID"
          class="col-lg-6 col-12"
          label="branches"
          multiple
          tag="div"
        />
      </v-row>
    </v-col>

    <v-col cols="12" lg="5">
      <form-categories
        v-model="obProduct.category_id"
        :auto-assign="!obProduct.id"
      />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue, Watch } from "vue-property-decorator";
import { Product } from "@planetadeleste/vue-mc-shopaholic";
import { AppModule } from "@/store/app";
import { ProductGwData, TaxType } from "@planetadeleste/vue-mc-gw";

import FormCategories from "@/components/form/Categories.vue";
import BrandsSelect from "@/modules/brands/components/BrandsSelect.vue";
import CompanyPreview from "@/modules/companies/components/CompanyPreview.vue";
import TaxTypesSelect from "@/modules/taxtypes/components/TaxTypesSelect.vue";
import BranchSelect from "@/modules/companies/components/BranchSelect.vue";
import CodeFieldText from "@/components/form/fields/CodeFieldText.vue";
import AccountCodeSelect from "@/modules/accounts/components/AccountCodeSelect.vue";
import CompanyLabelsDialog from "@/modules/companies/components/CompanyLabelsDialog.vue";
import { canModuleAccess } from "@/services/moduleAccess";
import { get, isEmpty, map, set } from "lodash";

@Component({
  components: {
    CompanyLabelsDialog,
    AccountCodeSelect,
    CodeFieldText,
    FormCategories,
    BrandsSelect,
    CompanyPreview,
    TaxTypesSelect,
    BranchSelect,
  },
})
export default class FormTabSettings extends Vue {
  @VModel({ type: Object, default: false }) obProduct!: Product;
  @Prop(Boolean) readonly taxReadonly!: boolean;
  @Prop(Boolean) readonly accountSalesRequired!: boolean;
  @Prop(Boolean) readonly accountPurchaseRequired!: boolean;

  // obProductGw: ProductGwData | Record<string, any> = {};

  get company() {
    return AppModule.company;
  }

  get branch() {
    return AppModule.branch;
  }

  get branchListID(): number[] {
    return this.obProduct.get("branch_list_id", map(this.branchList, "id"));
  }

  set branchListID(sValue: number[]) {
    this.obProduct.set("branch_list_id", sValue);
  }

  get branchList() {
    if (
      !this.company ||
      !this.company.branches ||
      !this.company.branches.length
    ) {
      return [];
    }

    return this.company.branches;
  }

  get taxTypeId() {
    return this.obProductGw.taxtype_id;
  }

  // set taxTypeId(sValue: number | string) {
  //   this.obProductGw.taxtype_id = sValue;
  // }

  get hasAccounting(): boolean {
    return canModuleAccess("accounting.entries");
  }

  get obProductGw(): ProductGwData | Record<string, any> {
    return this.obProduct.get("product_gw", {});
  }

  // set obProductGw(obData: ProductGwData | Record<string, any>) {
  //   this.obProduct.set("product_gw", obData);
  // }

  @Watch("obProductGw", { deep: true })
  onChangeProductGw(obData: ProductGwData | Record<string, any>) {
    if (this.obProduct.id && isEmpty(obData)) {
      obData = { product_id: this.obProduct.id };
    }

    this.obProduct.set("product_gw", obData);
  }

  onSelectTaxType(obItem: TaxType) {
    this.$set(this.obProductGw, "taxtype_id", obItem.id);
  }

  mounted() {
    this.$nextTick(() => {
      // this.branchListID = this.obProduct.get("branch_list_id", []);
      // if (!this.branchListID.length && this.branch.id) {
      //   this.branchListID.push(this.branch.id);
      // }

      // this.obProductGw = this.obProduct.get("product_gw", {}) || {};
      if (!get(this.obProductGw, "product_id")) {
        this.obProductGw.product_id = this.obProduct.id;
      }
    });
  }
}
</script>
